import React, { useCallback, useLayoutEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import "./index.css"
import { size } from "lodash"

export const MainText = () => {
  const { smallPlaceholder, largePlaceholder } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
        }
      }
      smallPlaceholder: file(
        relativePath: { eq: "placeholder_red-circles_small.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      largePlaceholder: file(
        relativePath: { eq: "placeholder_red-circles_large.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const CONTENT = [
    "We build and scale brands",
    // <Img
    //   key="inline-image-1"
    //   fluid={smallPlaceholder.childImageSharp.fluid}
    //   alt="Small placeholder image"
    // />,
    "to live at the intersection",
    // <Img
    //   key="inline-image-2"
    //   fluid={largePlaceholder.childImageSharp.fluid}
    //   alt="Large placeholder image"
    // />,
    "of culture and commerce. We are Dim Sum Club.",
  ].reduce((acc, cur) => {
    if (typeof cur === "string") {
      return [...acc, ...cur.split(" ")]
    } else {
      return [...acc, cur]
    }
  }, [])

  // A list of spans' offsets from the top. Used to determine which "lines" can be animated in
  const lineOffsetList = useRef({})
  const [lineNumberByWord, setLineNumberByWord] = useState({})
  // The list of line numbers that have orphaned words
  const [linesWithOrphanWords, setLinesWithOrphanWords] = useState([])

  useLayoutEffect(() => {
    // When the page first loads, get the y height of each span and store it
    let lineNumber = 0
    const _lineNumberByWord = {}
    document.querySelectorAll("header .text-reveal").forEach((span, i) => {
      const offset = Math.floor(span.getBoundingClientRect().top)

      if (!lineOffsetList.current[offset]) {
        // Indicates this is a new line
        lineOffsetList.current[offset] = true
        lineNumber += 1
      }
      _lineNumberByWord[i] = lineNumber
    })
    setLineNumberByWord(_lineNumberByWord)

    // Get all the orphaned lines
    const lines = Object.values(_lineNumberByWord)
    const orphanLines = lines.reduce((acc, cur, i) => {
      const numWordsInLine = Object.values(_lineNumberByWord).filter(
        x => x === cur
      ).length
      // console.log(cur, numWordsInLine)
      if (numWordsInLine > 1 || i === lines.length - 1) {
        return acc
      } else {
        return [...acc, cur]
      }
    }, [])
    // console.log(orphanLines)
    setLinesWithOrphanWords(orphanLines)

    // All these grouped things are "lines" and will eventually tell us how to animate in the page
  }, [])

  // Not using React states here, since we want it to reset every time this page is rendered
  let imageIndex = 1
  let wordIndex = 0 // This one needs to be zero-indexed to get from the array. Sorry for the mess!

  useLayoutEffect(() => {
    // Fetch the orphaned words
    document.querySelectorAll(".orphaned-word").forEach(orphanedWord => {
      // console.log("CALCULATING FOR AN ORPHANED WORD", orphanedWord)
      // Make it's letter spacing such that it takes up the full width
      let maxLetterSpacing = 0
      ;[8, 4, 2, 1, 0.5, 0.25, 0.125].forEach(sizeToTry => {
        // Determine how much space needs to be
        orphanedWord.style.letterSpacing = `${maxLetterSpacing + sizeToTry}em`

        // Get its parent
        const parent = orphanedWord.parentElement

        // console.log("TRYING ", sizeToTry)
        if (
          orphanedWord.getBoundingClientRect().width <=
          parent.getBoundingClientRect().width
        ) {
          // console.log("it fitted")
          maxLetterSpacing += sizeToTry
        } else {
          // console.log("it did not fit")
        }
      })
    })
  }, [linesWithOrphanWords])

  return (
    <>
      {CONTENT.map((item, i) => {
        const lineNumber = lineNumberByWord[wordIndex++]
        const imageNumber = imageIndex++
        return typeof item === "string" ? (
          <>
            <span
              key={`text-item-${i}`}
              className={`text-reveal line-${lineNumber} ${
                linesWithOrphanWords.indexOf(lineNumber) > -1 && item.length > 3
                  ? "orphaned-word"
                  : ""
              }`}
            >
              {item}
            </span>{" "}
          </>
        ) : (
          <>
            <span
              className={`image-reveal index-${imageNumber}`}
              key={`image-item-${i}`}
            >
              {item}
            </span>{" "}
          </>
        )
      })}
    </>
  )
}
