import React, { useCallback, useLayoutEffect, useRef } from "react"
import { DscLogoSvg } from "../dsc-logo"
import "./index.css"

export const AnimatedDscLogo = () => {
  // Store whether we're done the intro animation or not
  const animationComplete = useRef(false)

  // Add some kind of mouse handling
  const handleMouse = useCallback(e => {
    if (!animationComplete.current) {
      return
    }

    // Do something with e;
    const mouseXRelative = e.clientX / window.innerWidth

    // Set the positions of the elements on the page to match
    // Apologies for the weird formatting, prettier is doing this and I hate it
    ;[0, 1, 2, 3, 4].forEach(i => {
      const el = document.querySelector(`.dsc-logo--layer-${i}`)
      if (document.querySelector(".dsc-logo:hover")) {
        el.style.left = `${8 * i * 0.5}%`
      } else {
        el.style.left = `${8 * i * mouseXRelative}%`
      }
    })
  }, [])

  // Adding the mouse event listener
  useLayoutEffect(() => {
    window.addEventListener("mousemove", handleMouse)
    setTimeout(() => {
      animationComplete.current = true
    }, 4000)

    return () => window.removeEventListener("mousemove", handleMouse)
  }, [handleMouse])

  return (
    <div className="logo-container">
      {[0, 1, 2, 3, 4].map((n, i) => (
        <DscLogoSvg
          key={n}
          className={`dsc-logo dsc-logo--layer-${n}`}
          // ref={logoLayerRefs[i]}
        />
      ))}
    </div>
  )
}
