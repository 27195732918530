import { useCallback, useLayoutEffect, useState } from "react"
/**
 * The names of the themes used by the website
 */
export const THEMES = ["black", "red", "white"]

/**
 * A special hook that switches between the themes exported above
 *
 * Returns a function that can be used to switch to the next theme
 */
export const useThemeSwitcher = (initialThemeIndex = 0) => {
  // The index of the theme that's selected in the list above
  const [themeIndex, setThemeIndex] = useState(initialThemeIndex)

  // Increments the theme forward by one when called
  const switchTheme = useCallback(() => {
    setThemeIndex((themeIndex + 1) % THEMES.length)
  }, [themeIndex])

  // Listens for theme changes and updates the body class to be .theme-{themeName}
  useLayoutEffect(() => {
    // Remove any classes from the body for any theme that it currently isn't
    document.body.classList.remove(
      ...THEMES.filter((_, i) => i !== themeIndex).map(
        theme => `theme-${theme}`
      )
    )
    // Add a new class to the body for the current theme
    document.body.classList.add(`theme-${THEMES[themeIndex]}`)
  }, [themeIndex])

  return switchTheme
}
