import React from "react"
import { useThemeSwitcher } from "../hooks/use-theme-switcher"
import Layout from "../components/layout"
import { MainText } from "../components/main-text"
import { LogoButton } from "../components/logo-button"

const IndexPage = () => {
  const switchTheme = useThemeSwitcher()

  return (
    <Layout>
      <header>
        <LogoButton onClick={switchTheme} />
        <MainText />
      </header>
    </Layout>
  )
}

export default IndexPage
