import React, { useCallback } from "react"
import "./index.css"
import { AnimatedDscLogo } from "../animated-dsc-logo"

// eslint-disable-next-line react/prop-types
export const LogoButton = ({ onClick = () => {} }) => {
  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      onClick()
    },
    [onClick]
  )

  return (
    <>
      {/* This may look dumb, but the form is for a11y purposes. The button can be focused with a keyboard and the user can press enter */}
      <form className="logo-button" onSubmit={handleSubmit}>
        <button className="logo-button__button" type="submit">
          <AnimatedDscLogo />
        </button>
      </form>
    </>
  )
}
